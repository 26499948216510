export default class App {
	constructor() {
		this._resizeFunctionsArr = [];
		this._resizeFunctions = {};
		this._resizeFunctionsCounter = 0;
		this._resizeTimeout = null;
		this.resizeThrottle = false;

		this._scrollFunctionsArr = [];
		this._scrollFunctions = {};
		this._scrollFunctionsCounter = 0;
		this._scrollTimeout = null;
		this.scrollThrottle = false;

		this._resizeAnimationPreventerTimeout = 200;
	}

	init() {
		// this.detectBrowser();
		this.setEventListeners();
		this.fullHeightListener();
		// this.resizeAnimationPreventer();
		// this.initScrollTo();
		// this.initScrollToTop();
		// this.initLabelClick();

		return true;
	}

	setEventListeners() {
		window.addEventListener('resize', this.resize.bind(this), {
			passive: true
		});
		document.addEventListener('scroll', this.scroll.bind(this), {
			passive: true
		});

		document.querySelectorAll('main form label').forEach(label => {
			label.addEventListener('keydown', e => {
				if (e.key.toLowerCase().substr(0, 3) == 'ent') {
					label.click();
				}
			});
		});

		Array.from(document.querySelectorAll('a')).filter(a => !a.hasAttribute('href')).forEach(a => {
			if (!a.hasAttribute('tabindex')) {
				a.setAttribute('tabindex', '0');
			}

			a.addEventListener('keydown', e => {
				if (e.key.toLowerCase().substr(0, 3) == 'ent') {
					a.click();
				}
			});
		});
	}

	resize() {
		const runFunctions = () => {
			for (let i = 0, length = this._resizeFunctionsArr.length; i < length; i++) {
				this._resizeFunctionsArr[i]();
			}
		};
		if (this._resizeTimeout !== null) {
			return;
		}
		runFunctions();
		if (this.resizeThrottle) {
			this._resizeTimeout = setTimeout(() => {
				this._resizeTimeout = null;
				runFunctions();
			}, this.resizeThrottle);
		}
	}

	/**
	 * @param {function} func Function to be called on a resize event
	 */
	registerResizeEvent(func) {
		this._resizeFunctions[this._resizeFunctionsCounter] = func;
		this._resizeFunctionsCounter = this._resizeFunctionsCounter + 1;

		// Rebuild array of events
		this._resizeFunctionsArr = [];
		Object.keys(this._resizeFunctions).forEach(key => {
			this._resizeFunctionsArr.push(this._resizeFunctions[key]);
		});

		return this._resizeFunctionsCounter - 1;
	}

	/**
	 * @param {Number} func Function's ID to be removed from the resize functions
	 */
	unregisterResizeEvent(func) {
		if (this._resizeFunctions[func]) {
			// Found
			delete this._resizeFunctions[func];

			// Rebuild array of events
			this._resizeFunctionsArr = [];
			Object.keys(this._resizeFunctions).forEach(key => {
				this._resizeFunctionsArr.push(this._resizeFunctions[key]);
			});

			return true;
		} else {
			// Not found
			return false;
		}
	}

	/**
	 * @description Fires all registered events for a scroll event in the document
	 */
	scroll() {
		const runFunctions = () => {
			for (let i = 0, length = this._scrollFunctionsArr.length; i < length; i++) {
				this._scrollFunctionsArr[i]();
			}
		};
		if (this._scrollTimeout !== null) {
			return;
		}
		runFunctions();
		if (this.scrollThrottle) {
			this._scrollTimeout = setTimeout(() => {
				this._scrollTimeout = null;
				runFunctions();
			}, this.scrollThrottle);
		}
	}

	/**
	 * @param {function} func Function to be called on a scroll event
	 */
	registerScrollEvent(func) {
		this._scrollFunctions[this._scrollFunctionsCounter] = func;
		this._scrollFunctionsCounter = this._scrollFunctionsCounter + 1;

		// Rebuild array of events
		this._scrollFunctionsArr = [];
		Object.keys(this._scrollFunctions).forEach(key => {
			this._scrollFunctionsArr.push(this._scrollFunctions[key]);
		});

		return this._scrollFunctionsCounter - 1;
	}

	/**
	 * @param {Number} func Function's ID to be removed from the scroll functions
	 */
	unregisterScrollEvent(func) {
		if (this._scrollFunctions[func]) {
			// Found
			delete this._scrollFunctions[func];

			// Rebuild array of events
			this._scrollFunctionsArr = [];
			Object.keys(this._scrollFunctions).forEach(key => {
				this._scrollFunctionsArr.push(this._scrollFunctions[key]);
			});

			return true;
		} else {
			// Not found
			return false;
		}
	}

	/**
	 * @description Adds a 'preventAnimations' class to the html tag on resizing
	 */
	resizeAnimationPreventer() {
		this.resizeTimer = null;
		this.windowWidth = window.innerWidth;
		this.registerResizeEvent(() => {
			if (window.innerWidth !== this.windowWidth) {
				document.documentElement.classList.add('preventAnimations');
				clearTimeout(this.resizeTimer);

				this.resizeTimer = setTimeout(() => {
					document.documentElement.classList.remove('preventAnimations');
				}, this._resizeAnimationPreventerTimeout);

				this.windowWidth = window.innerWidth;
			}
		});
	}

	/**
	 * @description Adds a CSS variable '--fullHeight' to the html tag that creates an 'accurate 100vh'
	 */
	fullHeightListener() {
		const fullHeight = () => {
			document.documentElement.style.setProperty('--fullHeight', `${window.innerHeight}px`);
		};
		this.registerResizeEvent(fullHeight);
		fullHeight();
	}

	/**
	 * @description Adds a browser-specific class to the html tag
	 */
	detectBrowser() {
		// Opera 8.0+
		let isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

		// Firefox 1.0+
		let isFirefox = typeof InstallTrigger !== 'undefined';

		let isIphone = window.navigator.userAgent.match(/iPhone/i);

		let isIpad = window.navigator.userAgent.match(/iPad/i);

		let isSafari = window.navigator.userAgent.match(/Safari/i) && (window.navigator.userAgent.match(/iPhone/i) || window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/macOS/i));

		// Internet Explorer 6-11
		let isIE = /*@cc_on!@*/ false || !!document.documentMode;

		// Edge 20+
		let isEdge = !isIE && !!window.StyleMedia;

		// Chrome
		let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

		// Blink engine detection
		let isBlink = (isChrome || isOpera) && !!window.CSS;

		if (isOpera) {
			document.documentElement.classList.add('opera');
		}

		if (isFirefox) {
			document.documentElement.classList.add('firefox');
		}

		if (isSafari) {
			document.documentElement.classList.add('safari');
		}

		if (isIphone) {
			document.documentElement.classList.add('iphone');
		}

		if (isIpad) {
			document.documentElement.classList.add('ipad');
		}

		if (isIE) {
			document.documentElement.classList.add('ie');
		}

		if (isEdge) {
			document.documentElement.classList.add('edge');
		}

		if (isChrome) {
			document.documentElement.classList.add('chrome');
		}

		if (isBlink) {
			document.documentElement.classList.add('blink');
		}
	}

	/**
	 * @description Scroll element into view
	 */
	initScrollTo() {
		if (window.scrollTo) {
			document.querySelectorAll('[data-scroll-to]').forEach(el => {
				el.addEventListener('click', e => {
					e.preventDefault();
					const target = document.querySelector(el.dataset.scrollTo);

					const scrollPosition = this.elementCoords(target).top - (window.innerWidth > this.breakpoints.md.size ? 150 : 70);
					window.scrollTo({
						top: scrollPosition,
						behavior: 'smooth',
					});
				});
			});
		}
	}

	scrollTo(selector) {
		let target;
		if (typeof selector == 'string') {
			target = document.querySelector(selector);
		} else {
			target = selector;
		}

		if (target) {
			const scrollPosition = this.elementCoords(target).top - (window.innerWidth > this.breakpoints.md.size ? 150 : 70);
			window.scrollTo({
				top: scrollPosition,
				behavior: 'smooth',
			});
		}
	}

	/**
	 * @description Basic random number generator
	 * @param {Number} min 
	 * @param {Number} max 
	 * @returns {Number}
	 */
	random(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	/**
	 * @description Basic random string generator
	 * @param {Number} length
	 * @returns {String}
	 */
	randomString(length) {
		let result = '';
		const characters = 'AB CDE FG HIJKLM NO PQRST UVWXYZa bcde fghi jklmnop qrstuvwx yz0 12345 6789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result.trim().replace(/\s\s/g, ' ');
	}

	/**
	 * @description Retrieves absolute position of an element relative to the page
	 * @param {HTMLElement} el 
	 * @returns {Object}
	 */
	elementCoords(el) {
		const box = el.getBoundingClientRect();

		const body = document.body;
		const docEl = document.documentElement;

		const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
		const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

		const clientTop = docEl.clientTop || body.clientTop || 0;
		const clientLeft = docEl.clientLeft || body.clientLeft || 0;

		const top = box.top + scrollTop - clientTop;
		const left = box.left + scrollLeft - clientLeft;

		return {
			top: Math.round(top),
			left: Math.round(left)
		};
	}

	initScrollToTop() {
		this.scrollToTopContainer = document.querySelector('.scrollToTop');

		if (this.scrollToTopContainer) {
			const button = this.scrollToTopContainer.querySelector('button');
			const scroll = () => {
				if (document.documentElement.scrollTop > 400) {
					this.scrollToTopContainer.classList.add('active');
				} else {
					this.scrollToTopContainer.classList.remove('active');
				}
			};

			this.registerScrollEvent(scroll.bind(this));

			button.addEventListener('click', () => {
				this.scrollToTopContainer.classList.add('moving');
				button.blur();
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
				setTimeout(() => this.scrollToTopContainer.classList.remove('moving'), 1500);
			});
		}
	}

	initSelectric() {
		if (document.querySelectorAll('select[selectric="true"]').length) {
			try {
				import( /* webpackChunkName: "modules/dropdown" */ 'selectric').then(() => {
					document.querySelectorAll('select[selectric="true"]').forEach(select => {
						$(select).selectric({
							arrowButtonMarkup: `<b class="button"><svg class="sprite icon_link_chevron"><use xlink:href="#icon_link_chevron"></use></svg></b>`
						});

						// Add a hidden label around the selectric input for accessibility.
						$('.selectric-input').wrap('<label class="d-none" aria-hidden="true">Dropdown</label>');

						this.selectricCBs.forEach(cb => {
							cb();
						});

						this.selectricCBs = [];
					});
				});
			} catch (e) {
				console.error(e);
			}
		}
	}

	/**
	 * @description Makes tags with the data-copy property clickable, copies its content to the clipboard
	 */
	initCopy() {
		document.querySelectorAll('[data-copy]').forEach(el => {
			el.addEventListener('click', () => {
				try {
					const copyContent = el.dataset.copy;
					const input = document.createElement('input');

					input.type = 'text';
					input.style.position = 'absolute';
					input.style.height = 0;
					input.style.overflow = 'hidden';
					input.value = copyContent;

					document.body.appendChild(input);
					input.select();
					document.execCommand('copy');
					input.parentNode.removeChild(input);
				} catch (e) {
					this.toast('Something unexpected went wrong', 'error');
					console.error(e);
				}
			});
		});
	}

	initPrintListener() {
		this.printTexts = Array.from(document.querySelectorAll('[data-print]'));

		if (this.printTexts.length) {
			let printElements = [];

			window.addEventListener('beforeprint', e => {
				this.printTexts.forEach(printParent => {
					let span = document.createElement('span');
					span.innerHTML = printParent.dataset.print;
					span.classList.add('printText');

					printElements.push(span);

					printParent.appendChild(span);
				});
			});

			window.addEventListener('afterprint', e => {
				printElements.forEach(printElement => {
					printElement.remove();
				});

				printElements = [];
			});
		}
	}

	initLabelClick() {
		document.querySelectorAll('label[for]').forEach(label => {
			label.addEventListener('keydown', e => {

				switch (e.key.toLowerCase()) {
					case ' ':
						e.preventDefault();
					case 'space':
					case 'enter':
						label.click();
						break;
				}
			});
		});
	}

	getParameter(name) {
		let result = null,
			tmp = [];
		let items = location.search.substr(1).split("&");
		for (let index = 0; index < items.length; index++) {
			tmp = items[index].split("=");
			if (tmp[0] === name) {
				result = decodeURIComponent(tmp[1]);
			}
			if (result === 'undefined') {
				result = true;
			}
		}
		return result;
	}

	loadGoogleMapsApi(apiKey) {
		if (this.googleMapsPromise) {
			return this.googleMapsPromise;
		}

		this.googleMapsPromise = new Promise((resolve, reject) => {
			// Create public function that gets called when Maps is loaded
			let functionName = `mapsLoaded_${Math.random().toString(12).substring(2, 12) + Math.random().toString(12).substring(2, 6)}`;
			window[functionName] = () => {
				delete window[functionName];
				resolve();
			};

			const script = document.createElement('script');
			script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${functionName}&libraries=places&region=NL&language=nl&v=weekly`);
			document.body.appendChild(script);
		});

		return this.googleMapsPromise;
	}
}