export default class PolyfillController {
  constructor() {
    this.polyfills = [];
    this.loaded = [];
  }

  init() {
    this.loadPolyfills();

    return Promise.all(this.polyfills);
  }

  loadPolyfills() {
    this.forEach();
    this.intersectionObserver();
    this.fetch();
    this.abortController();
    this.proxy();
    this.closest();
    this.before();
    this.remove();
    this.entries();
    this.customEvent();
  }

  forEach() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
      this.loaded.push('forEach');
    }
  }

  intersectionObserver() {
    if (!('IntersectionObserver' in window)) {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/intersectionobserver" */ 'intersection-observer'));
      this.loaded.push('intersectionObserver');
    }
  }

  fetch() {
    if (!window.fetch) {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/fetch" */ 'whatwg-fetch'));
      this.loaded.push('fetch');
    }
  }

  abortController() {
    if (typeof AbortController == 'undefined') {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/abortController" */ 'abortcontroller-polyfill/dist/polyfill-patch-fetch'));
      this.loaded.push('abortController');
    }
  }
  
  proxy() {
    if (!('Proxy' in window)) {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/proxy" */ 'es6-proxy-polyfill'));
      this.loaded.push('proxy');
    }
  }

  closest() {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
      this.loaded.push('matches');
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var el = this;
        do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
      this.loaded.push('closest');
    }
  }

  before() {
    (arr => {
      arr.forEach(function (item) {
        if (item.hasOwnProperty('before')) {
          return;
        }
        Object.defineProperty(item, 'before', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function before() {
            var argArr = Array.prototype.slice.call(arguments),
              docFrag = document.createDocumentFragment();

            argArr.forEach(function (argItem) {
              var isNode = argItem instanceof Node;
              docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
            });

            this.parentNode.insertBefore(docFrag, this);
          }
        });
      });
      this.loaded.push('before');
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
  }

  remove() {
    (arr => {
      arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
          return;
        }
        Object.defineProperty(item, 'remove', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function remove() {
            if (this.parentNode === null) {
              return;
            }
            this.parentNode.removeChild(this);
          }
        });
      });
      this.loaded.push('remove');
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
  }

  entries() {
    if (!Object.entries) {
      Object.entries = function( obj ){
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
          resArray[i] = [ownProps[i], obj[ownProps[i]]];
    
        return resArray;
      };
      this.loaded.push('entries');
    }
  }

  customEvent() {
    if (typeof (Event) === 'object') {
      function CustomEvent(event, params) {
        params = params || {
          bubbles: false,
          cancelable: false,
          detail: undefined
        };
        let evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      CustomEvent.prototype = window.Event.prototype;

      window.CustomEvent = CustomEvent;
      window.Event = CustomEvent;
      this.loaded.push('Custom Event');
    }
  }
}
