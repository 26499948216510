// App
import App from './app';
import PolyfillController from './controllers/polyfill';

// Always loaded controllers

(async () => {
    // document.querySelector('html').classList.remove('no-js');

    const polyfills = new PolyfillController();
    await polyfills.init();

    try {
        const app = new App();
        app.resizeThrottle = 10;
        app.scrollThrottle = 100;
        window.app = app;
    
        app.polyfillController = polyfills;

        // Modules
        const loadModules = () => {
            if (document.querySelector('.volunteerVacanciesOverview')) {
                console.log('Match!');
                import( /* webpackChunkName: "modules/volunteerVacanciesOverview" */ './controllers/volunteerVacanciesOverview/controller').then(e => {
                    app.volunteerVacanciesOverviewController = new e.default();
                });
            }
        };

        loadModules();
        await app.init();
    } catch(e) {
        console.error(`Something went wrong initializing the app`);
        throw e;
    };

    console.log(`App initialized`);
    // document.documentElement.classList.remove('preventAnimations');

})();